<template>
  <scrollactive active-class="active" class="my-nav" :offset="70">
    <nav v-if="data.csJson" class="navbar navbar-expand-lg fixed-top navbar-dark sticky solid-nav">
      <div class="container">
        <a class="navbar-brand scrollactive-item" href="#home">
          <div v-if="data.navJson.title !== ''">
            {{data.navJson.title}}
            <b-icon icon="house-fill" aria-hidden="true"></b-icon>
          </div>
          <img v-if="data.navJson['logo'] !== ''" :src="`${publicPath}assets/${data.portfolioName}/${data.navJson['logo']}`" alt="Company Logo" class="img-fluid rounded" style="max-width: 60px;">
        </a>
        <a class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
           aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" @click="menuActive = !menuActive">
          <b-icon icon="menu-app" aria-hidden="true"></b-icon>
        </a>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mx-auto">
            <li v-for="(c, index) in data.csJson" :key="index" class="nav-item">
              <a v-if="c['showInNav']" class="nav-link scrollactive-item" :href="`#${c['navId'].toLowerCase()}`">{{ c['navName'] }}</a>
            </li>
          </ul>
          <div>
            <b-button-toolbar>
              <b-button-group class="mr-1">
                <b-button v-for="(platform, index) in data.social" :key="index" :title="platform.title" variant="primary"
                          size="sm" :href="platform.href" target="_blank">
                  <b-icon :icon="platform.icon" aria-hidden="true" class="text-white"></b-icon>
                </b-button>
              </b-button-group>
            </b-button-toolbar>
          </div>
        </div>
      </div>
    </nav>
  </scrollactive>
</template>

<script>
export default {
  name: 'Nav',
  props: {},
  data () {
    return {
      menuActive: false,
      publicPath: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    data() {
      const navJson = this.$webData.components.find((e) => e.name === 'Nav1');
      const footerIndex = this.$webData.components.findIndex((e) => e.name === 'Footer');
      const portfolioName = this.$portfolioName;
      return {
        navJson: navJson,
        portfolioName: portfolioName,
        social: this.$webData.social,
        csJson: this.$webData.components.slice(0, footerIndex)
      };
    }
  },
  mounted() {
    //console.log(this.data.csJson)
  },
  methods: {
    /*onItemChanged(event, currentItem, lastActiveItem) {
      console.log(event)
      console.log(currentItem)
      console.log(lastActiveItem)
      // here you have access to everything you need regarding that event
    }*/
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navbar {
  box-shadow: 0 0 4px 1px $primary;
}

.solid-nav {
  background-color: #ffffff;
  transition-duration: 0.5s;

  .navbar-brand {
    color: $secondary !important;
  }

  a {
    color: $secondary !important;

    &.active {
      color: $primary !important;
    }
  }
}

@media (max-width: 768px) {
  .navbar {
    background-color: #fff;

    .navbar-brand {
      color: $secondary !important;
    }

    a {
      color: $secondary !important;
    }

    .active {
      a {
        color: $primary !important;
      }
    }
  }
}
</style>
